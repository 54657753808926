.ant-layout-sider {
    background-color: $color-blue !important;
    min-width: 252px !important;
}

.ant-layout-sider-collapsed {
    background-color: $color-blue !important;
    flex: 0 0 80px;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
}

.ant-layout-sider-trigger {
    background-color: $color-blue !important;
    color: $color-white !important;
}

.layout-container-logo {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;


    p {
        font-size: 32px;
        font-weight: 700;
    }

    &__mini {
        width: 50px;
    }

}

.ant-menu-item-selected {
    background-color: $color-blue-light !important;
    font-weight: bold;

    span {
        a {
            color: $color-white !important;
        }
    }
}

.ant-menu-light {
    color: $color-white !important;
}

.ant-menu-item {
    gap: 1rem;
}

.ant-menu-inline-collapsed {
    margin-top: 10px;
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: none
}

.ant-menu-light.ant-menu-root.ant-menu-vertical {
    border-inline-end: none
}

.ant-menu-title-content {
    display: flex;

    a {
        font-size: 14px;
        font-weight: 700;
    }
}



.ant-layout-sider-children {
    margin-top: 30px !important;
    padding-top: 0px !important;
}

.ant-layout-content {
    background-color: $color-white !important;
}

.ant-menu {
    background-color: $color-blue !important;
}

.container-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-blue !important;

    button {
        color: $color-black !important;
    }
}

.ant-steps.ant-steps-vertical {
    display: flex !important;
    flex-direction: row;
    overflow-x: none !important;
}

.ant-steps-item-icon {
    margin-inline-end: 10px !important;
}

.buttons-steps {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    margin-inline: 20px;
}

.ant-drawer-content-wrapper {
    width: 100% !important;
}

.ant-drawer-body {
    background-color: $color-blue;
}

.ant-drawer-header {
    background-color: $color-blue;
}

.ant-drawer-title {
    color: $color-white !important;
}

.ant-drawer-close {
    color: $color-white !important;
}