.button-container {
    &__button-blue {
        background-color: $color-blue;
        z-index: 1;
        font-weight: bold;
        font-size: 12px;
        text-decoration: none;
        border: transparent;
        right: 0;
        transition: .5s;
        width: 100%;
        border-radius: 10px;
        color: $color-white;

        &:hover {
            cursor: pointer;
        }
    }

    &__button-login {
        background-color: $color-blue;
        z-index: 1;
        font-weight: bold;
        font-size: 12px;
        text-decoration: none;
        border: transparent;
        right: 0;
        transition: .5s;
        border-radius: 10px;
        color: $color-white;

        &:hover {
            cursor: pointer;
        }
    }

    &__button-blueDisable {
        background-color: $color-grey;
        z-index: 1;
        font-weight: bold;
        font-size: 12px;
        text-decoration: none;
        border: transparent;
        right: 0;
        transition: .5s;
        width: 100%;
        border-radius: 10px;
        padding: 14px 28px 14px 28px;
        color: $color-white;

        &:hover {
            cursor: not-allowed;
        }
    }

    &__button-create {
        background-color: $color-blue;
        z-index: 1;
        font-weight: bold;
        font-size: 12px;
        text-decoration: none;
        border: transparent;
        right: 0;
        transition: .5s;
        border-radius: 10px;
        color: $color-white;

        &:hover {
            cursor: pointer;
        }
    }

    &__button-edit {
        background-color: $color-grey-white;
        z-index: 1;
        font-weight: bold;
        font-size: 12px;
        text-decoration: none;
        border: transparent;
        right: 0;
        transition: .5s;
        width: 100%;
        border-radius: 10px;
        padding: .8rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &__button-back {
        background-color: $color-blue;
        z-index: 1;
        font-weight: bold;
        font-size: 12px;
        color: $color-white;
        text-decoration: none;
        border: transparent;
        right: 0;
        transition: .5s;
        width: 2.5rem;
        border-radius: 10px;

        &:hover {
            color: $color-white;
            cursor: pointer;
        }
    }

    &__button-logout {
        background-color: $color-blue;
        z-index: 1;
        font-weight: bold;
        font-size: 15px;
        color: $color-white;
        text-decoration: none;
        border: transparent;
        right: 0;
        transition: .5s;
        width: 100%;
        border-radius: 10px;
        padding: 10px 10px 10px 10px;

        &:hover {
            color: $color-white;
            cursor: pointer;
        }
    }

}

.plus-symbol {
    font-size: 25px;
    margin: 10px;
}

.anticon-plus {
    font-size: 20px !important;
}

.ant-upload {
    span {
        button {
            display: flex;
            align-items: center;
            border-radius: 10px;
            padding: 1.3rem;
            color: $color-white;
            background-color: $color-blue;
            font-weight: bold;
            font-size: 12px;
        }
    }
}

.button-container .ant-btn-primary {
    background-color: $color-blue;
    z-index: 1;
    font-weight: bold !important;
    font-size: 12px;
    text-decoration: none;
    border: transparent;
    right: 0;
    transition: .5s;
    width: 100%;
    border-radius: 10px;
    color: $color-white;

    &:hover {
        cursor: pointer;
    }

}