.container-form {
    padding: 2rem;

    &__slider {
        margin-bottom: 8px;
        width: 200px;
    }

    &__button {
        padding-top: 2rem;
        display: flex;
        justify-content: center;
    }

    &__detail {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem;
    }
}

.container-form-user {

    &__direction {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__item {
        width: 50% !important;
    }
}

.mobile-form-user {
    &__direction {
        display: flex;
        flex-direction: column;

        &__item {
            width: 100%;
        }
    }
}


.column-detail {
    flex: 1 0 30%;
    box-sizing: border-box;
    margin-inline: 1rem;

    &__title {
        font-size: 20px;
        font-weight: bold;
    }

    &__info {
        display: flex;
        gap: 10px;
        word-wrap: break-word;
        overflow: hidden;
    }

    &__info-label {
        font-weight: bold;
    }
}

.column-detail-mobile {
    display: flex;
    flex-direction: column;

    h3 {
        display: flex;
        justify-content: center !important;
    }
}

.form-info-container {
    &__input-password {
        display: flex;
        margin: 0;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        line-height: 1.5714285714285714;
        list-style: none;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        background-color: #ffffff;
        background-image: none;
        border-width: 1px;
        border-style: solid;
        border-color: #d9d9d9;
        border-radius: 6px;
        transition: all 0.2s;
    }
}