.body-container {
    &__button {
        justify-content: flex-end;
        padding: 1rem 2.5rem;
        display: flex;
    }

    &__table {
        margin-inline: 1rem;

        .flex-end-container {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            padding-right: 100px; 

            @media (max-width: 760px) {
                padding-right: 0px;
            }

            p {
                margin-right: 5px;

                &:first-child {
                    content: attr(data-paginatedtotalsum) " - ";
                }

                &:last-child {
                    content: attr(data-totalsum);
                }
            }
        }
    }

    &__pagination {
        display: flex;
        justify-content: flex-end;
        padding: 2rem;
    }

}

.ant-pagination-options-size-changer {
    display: none;
}

.ant-collapse-header {
    display: flex;
    align-items: center !important;
    background-color: $color-blue;
    color: $color-white !important;
}

.ant-collapse-footer {
    display: flex;
    align-items: center !important;
    background-color: $color-blue;
    color: $color-white !important;
}

.collapsed-renderCategory {
    display: flex;
    justify-content: space-between;
    align-items: center;


    &__content {
        margin-block: 1rem;

        p {
            margin-inline: 1rem;
        }
    }
}

.ant-collapse {
    margin-inline: 1rem;
}

.ant-collapse-content-box {
    padding-inline: 0px !important;
    padding: 0px !important;
    gap: 10px;
}

.collapsed-button-container {
    display: flex;
    gap: 1rem;

    .button-container {
        button {
            width: 45px !important;
            height: 45px !important;
            display: flex;
            justify-content: center;

            svg {
                margin: none;
            }
        }
    }
}

.map-container {
    display: flex;
    margin: auto;
    width: 90% !important;
    height: 40rem !important;
}

.fill-order {
    color: #9A031E !important
}

.fill-customer {
    color: #3559E0
}

.fill-route {
    color: #5C8374 !important
}

.legend-container {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    padding: 10px;
    border-radius: 5px;
    z-index: 1;
}

.legend-item {
    margin-bottom: 5px;
}

.legend-color-box {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.legend-text {
    display: inline-block;
}

.spin {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
  }