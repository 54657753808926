.login-container {
    height: 100vh;
    display: flex;

    &__logo {
        background-color: $color-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;

        img {
            max-width: 100%;
            height: 9.3rem;
        }
    }

    &__form {
        width: 50%;
        display: flex;
        flex-direction: column;
        margin-top: 10%;
        align-items: center;
    }

    &__inputs {
        width: 50%;
        margin-top: 2rem;
        padding: 2rem;

        input {
            padding: .5rem !important;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.button-back {
    margin-top: 2%;
    margin-left: 2%;
}

@include cel-phone {
    .login-container {
        width: 100%;
        flex-direction: column;

        &__logo {
            width: 100%;

            img {
                max-width: 100%;
                height: 5rem;
            }
        }

        &__form {
            width: 100%;

            p {
                margin-inline: 5%;
            }
        }

        &__inputs {
            display: flex;
            justify-content: center;
            width: 50%;
        }
    }

    .button-back {
        width: 10%;
        margin-top: 2%;
        margin-left: 2%;
    }
}