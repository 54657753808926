
.login-ant-input-affix-wrapper {
    border-radius: 20px;
    max-width: 20rem !important;
    min-width: 15rem !important;
}

.ant-form-item-explain-error{
    max-width: 15rem !important;
}

@include cel-phone {
    .login-ant-input-affix-wrapper {
        border-radius: 20px;
        max-width: 15rem !important;
    }

    .ant-form-item-explain-error{
        max-width: 15rem !important;
    }

}