.ant-table {
  background-color: transparent !important;
}

.ant-table-container {
  background-color: transparent !important;
  margin-top: .5%;
  margin-inline: 2%;

}


.ant-table-content {
  background-color: transparent !important;
  border-color: red !important;

  .ant-table-thead {
    tr {
      .ant-table-cell {
        background: $color-blue;
        color: $color-white;
        font-weight: bold;
        font-size: 13px;

      }

      .ant-table-cell::before {
        background-color: transparent !important;
      }
    }
  }

  .ant-table-tbody {
    tr {
      .ant-table-cell {
        color: $color-black;
        background-color: $color-white !important;
        font-weight: 100 !important;
        font-weight: bold;
        font-size: 15px;
        border-color: $color-blue !important;

      }

      div {
        img {
          border-radius: 50%;
          aspect-ratio: 1/1;
          object-fit: cover;

        }
      }
    }
  }
}

.table-button-delete {
  background-color: $color-blue !important;
}


.ant-table-content .ant-table-tbody tr .ant-table-cell {
  white-space: nowrap !important;
  overflow: hidden !important;
  max-width: 10px !important;
  text-overflow: ellipsis !important;
}