.container-header {
    display: flex;
    padding: 1rem;
    background-color: $color-blue;
    width: 100%;
    justify-content: space-between;

    h1 {
        color: $color-white;
        margin: 0;
    }

    img {
        height: 24px;
    }

    &__title {
        width: 50%;
        display: flex;
        justify-content: flex-end;
    }
}

@include cel-phone {
    .container-header {
        display: flex;
        justify-content: center;
        padding: 1rem;
        background-color: $color-white;

        h1 {
            color: $color-black;
            margin: 0;
        }
    }
}