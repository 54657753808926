.home-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        margin: 0;
    }

    img {
        max-width: 90%;
        width: 50rem;
        height: auto;
    }
}

.container-home {
    padding: 1rem;

    &__info {
        display: flex;
        justify-content: space-between;
        padding: 1rem;

    }
    

    &__count {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;
        box-shadow: 10px 10px 30px -6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 10px 10px 10px -6px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 10px 10px 30px -6px rgba(0, 0, 0, 0.3);

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 10px;
        }

        h3 {
            margin: 0px;
        }

        p {
            margin: 15px;
            max-width: 200px;
            font-size: 35px;
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        select {
            border: none;
        }
    }

    &__info-table {
        display: flex;
        justify-content: space-between;
        width: 100%;

        div {

            h3 {
                display: flex;
                justify-content: center;
            }
        }
    }

    &__info-chart {
        width: 100%;
        padding-top: 2rem;
    }
}

.select-home {
    width: 10rem !important;
    
    .ant-select-selector {
        border: none !important;
        border-bottom: 1px solid $color-blue !important;
    }
}


.container-home-mobile-header {
    display: flex;
}

.apexcharts-align-center {
    display: none !important;
}

@include cel-phone {
    .home-container {
        display: flex;
        height: auto;
        margin-top: 20%;


        h1 {
            font-size: 2rem;
            margin: 0;
        }

        img {
            height: 5rem;
        }
    }

}

.table-select {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 10px;

    select {
        border: none;
    }
}

.table-container {
    width: 50%;
}

.anticon-setting {
    svg {
        height: 20px;
        width: 20px;
    }
}

.ant-menu-item-group-list {
    background-color: $color-white;
}

.ant-menu-item-group-list:hover {
    background-color: $color-white;
}

.ant-menu-item-group-title {
    background-color: $color-white;
}

.ant-menu-item-group {
    background-color: $color-white
}

.ant-menu-vertical.ant-menu-sub {
    background-color: $color-white !important;
}

.apex-chart-mobile {
    overflow-x: visible;
}