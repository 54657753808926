.container-search {
    margin: 1.5rem;
    


    &__filters {
        margin: 1rem;
        display: grid;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    &__filters-button {
        display: grid;
        grid-column-start: 3;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
        align-self: flex-end;
    }
}

.cointainer-select-search {
    width: 200px;
    display: flex;
    justify-content: space-between;
}

.input-search {
    width: 200px;
    display: flex;
    justify-content: space-between;
}

.keon {
    width: 200px;
    margin-block: .5rem;

}

.ant-row {
    display: flex;
    justify-content: space-between;
    margin-inline: 1rem !important;
    margin-block: .5rem;
    
}